import { Button, Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { Link } from 'components/common/Link';
import styles from './PolicyCard.module.scss';

type PolicyCardProps = {
  title: string;
  description: string;
  href: string;
};

const PolicyCard = ({ description, href, title }: PolicyCardProps) => {
  const { t } = useTranslation();

  return (
    <Link className={styles.root} href={href}>
      <Stack as="section" className={styles.section} gap={4}>
        <Stack gap={2}>
          <Heading as="h2" variant="s">
            {title}
          </Heading>

          <Text variant="l">{description}</Text>
        </Stack>

        <Button
          as="div"
          className={styles.button}
          variant="outlined"
          trailingIcon="arrow-right"
          size="l"
        >
          {t('general.readMoreLabel')}
        </Button>
      </Stack>
    </Link>
  );
};

export { PolicyCard };
export type { PolicyCardProps };
