import Cookies from 'js-cookie';
import { flatten, isEmpty } from 'lodash';
import { create } from 'zustand';
import * as cookieConsent from 'services/cookieConsent';
import * as dataLayer from 'services/dataLayer';
import { getCookies } from 'services/support-api/cookies';
import { COOKIE_CATEGORIES } from '../../../constants';
import type { SavedCookieCategories } from '../../../types';

type BisquitStore = {
  actions: {
    pushToDataLayer: (cookieCategories: SavedCookieCategories) => void;
    saveCookies: (cookieCategories: SavedCookieCategories) => void;
    removeCookies: (cookieCategories: SavedCookieCategories) => Promise<void>;
  };
};

const useBisquitsStore = create<BisquitStore>()((set, get) => ({
  actions: {
    pushToDataLayer: (cookieCategories) => {
      const cookieConsentSelection = Object.entries(cookieCategories)
        .filter(([, value]) => value)
        .map(([key]) => key.replace(/Cookies$/, ''))
        .join(',');

      dataLayer.push('cookieConsent', {
        cookieConsentSelection,
      });
    },
    saveCookies: (cookieCategories) => {
      cookieConsent.remember(cookieCategories);
      get().actions.removeCookies(cookieCategories);
      get().actions.pushToDataLayer(cookieCategories);
    },
    removeCookies: async (cookieCategories) => {
      const rejectedCategoryIds = Object.keys(cookieCategories).filter(
        (key) => !cookieCategories[key as keyof SavedCookieCategories],
      );

      if (isEmpty(rejectedCategoryIds)) {
        return;
      }

      const categoriesToRemove = flatten(
        COOKIE_CATEGORIES.filter((category) => rejectedCategoryIds.includes(category.id)).map(
          ({ cookiebotCategory }) => cookiebotCategory,
        ),
      );

      const availableCookies = await getCookies();

      const cookiesToRemove = availableCookies?.filter((cookie) =>
        categoriesToRemove.includes(+cookie.Category),
      );

      cookiesToRemove?.forEach((cookie) => {
        Cookies.remove(cookie.Name);
      });
    },
  },
}));

const useBisquitsActions = () => useBisquitsStore((store) => store.actions);

export { useBisquitsActions };
export type { SavedCookieCategories };
