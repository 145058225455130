import { Stack, Text } from '@carvertical/ui';
import { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { NS_CONTENT } from 'constants/i18n';
import Anchor from 'components/common/Anchor';
import { TOC_EXTRACTABLE_TAG } from '../../constants';
import styles from './TableOfContents.module.scss';

type TableOfContentsProps = {
  className?: string;
  links: {
    id: string;
    children: React.ReactNode;
  }[];
};

const INTERSECTION_OBSERVER_SETTINGS = {
  rootMargin: '-40% 0% -60% 0%',
  threshold: 0,
};

const useIntersectingHeaderId = (ids: string[]) => {
  const observer = useRef<IntersectionObserver>();
  const [intersectingId, setIntersectingId] = useState('');

  useEffect(() => {
    observer.current = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          const heading = entry.target.querySelector(TOC_EXTRACTABLE_TAG);
          setIntersectingId(heading?.id || entry.target.id);
        }
      });
    }, INTERSECTION_OBSERVER_SETTINGS);

    const elements = document.querySelectorAll(ids.map((id) => `#${id}`).join(','));

    elements.forEach((element) => observer.current?.observe(element.parentElement ?? element));

    return () => observer.current?.disconnect();
  }, [ids]);

  return intersectingId;
};

const TableOfContents = ({ className, links }: TableOfContentsProps) => {
  const { t } = useTranslation(NS_CONTENT);
  const ids = useMemo(() => links.map(({ id }) => id), [links]);
  const rootRef = useRef<HTMLDivElement>(null);

  const intersectingId = useIntersectingHeaderId(ids);

  const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    setActiveId(intersectingId);
  }, [intersectingId]);

  useEffect(() => {
    setActiveId(window.location.hash.replace('#', ''));
  }, []);

  return (
    <Stack
      as="nav"
      crossAxisAlign="stretch"
      ref={rootRef}
      className={cx(styles.root, className)}
      gap={{ mobileUp: 2, tabletPortraitUp: 1 }}
    >
      <Text as="h2" variant="s+">
        {t('contents')}
      </Text>

      <Stack as="ul" className={styles.list} gap={0.5}>
        {links.map(({ children, id }) => (
          <li className={cx(styles.listItem, activeId === id && styles.active)} key={id}>
            <Anchor
              id={id}
              onClick={() => {
                setActiveId(id);
              }}
              className={styles.link}
              scroll={false}
              shallow={false}
            >
              <Text variant="s" textColor="inherited">
                {children}
              </Text>
            </Anchor>
          </li>
        ))}
      </Stack>
    </Stack>
  );
};

export { TableOfContents };
export type { TableOfContentsProps };
