import { Button, Container } from '@carvertical/ui';
import { motion, type AnimationProps } from 'framer-motion';
import { isEmpty } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useModal } from 'modules/shared';
import { useRouteData } from 'context/RouteDataProvider';
import * as cookieConsent from 'services/cookieConsent';
import type { Page } from 'types/content';
import { isExistingMarket, type ExistingMarkets } from 'utils/market';
import NomNomIllustration from '../../assets/nom-nom.svg';
import { BisquitsQuickSettingsText } from './BisquitsQuickSettingsText';
import { useBisquits } from './hooks';

const WHITELIST_PAGES: Page['id'][] = ['cookiePolicy'];
const NECESSARY_COOKIES_MARKETS: ExistingMarkets = ['france'];
const ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { y: '100%' },
    visible: { y: 0 },
    exit: { y: '100%' },
  },
  transition: {
    duration: 1.5,
    type: 'spring',
    bounce: 0,
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

const BisquitsQuickSettingsModal = () => {
  const { t } = useTranslation();
  const { market, page } = useRouteData();
  const { isModalOpen, open, close } = useModal('bisquitsQuickSettings');
  const { isModalOpen: isExtendedSettingsModalOpen, open: openExtendedSettingsModal } = useModal(
    'bisquitsExtendedSettings',
  );
  const { acceptAll, acceptNecessary } = useBisquits({ onSuccess: close });

  const necessaryCookieActionShown = isExistingMarket({
    markets: NECESSARY_COOKIES_MARKETS,
    market,
  });

  useEffect(() => {
    const alreadyConsented = !isEmpty(cookieConsent.get());
    const shown =
      !alreadyConsented &&
      !WHITELIST_PAGES.includes(page?.id || '') &&
      !isExtendedSettingsModalOpen;

    if (shown) {
      open();
    } else {
      close();
    }
  }, [page, open, close, isExtendedSettingsModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <dialog open={isModalOpen} title={t('cookieNotice.cookieSettingsLabel')}>
      <div className="shadow-lg fixed inset-0 z-20 bg-black/50" />

      <motion.div
        {...ANIMATION_PROPS}
        className="shadow-lg fixed bottom-0 left-0 right-0 z-50 max-h-[90%] overflow-y-auto overflow-x-hidden bg-white py-4"
      >
        <Container className="flex flex-col items-center">
          <div className="flex w-full flex-col sm:max-w-[600px] md:max-w-full md:flex-row md:items-center md:gap-4">
            <NomNomIllustration className="mb-2 h-8 w-8 flex-shrink-0 text-transparent sm:h-15 sm:w-15 md:mb-0 md:self-end" />

            <BisquitsQuickSettingsText onOpenSettings={openExtendedSettingsModal} />

            <div className="flex min-w-[272px] flex-col gap-2 md:ml-auto">
              <Button onClick={acceptAll} variant="black" size="m">
                {t('cookieNotice.acceptAllAction')}
              </Button>

              <Button
                size="m"
                variant="outlined"
                onClick={necessaryCookieActionShown ? acceptNecessary : openExtendedSettingsModal}
                className="hover:bg-black hover:text-white"
              >
                {necessaryCookieActionShown
                  ? t('cookieNotice.acceptNecessary')
                  : t('cookieNotice.settingsAction')}
              </Button>
            </div>
          </div>
        </Container>
      </motion.div>
    </dialog>
  );
};

export { BisquitsQuickSettingsModal };
