import InnerHtml from 'dangerously-set-html-content';
import cx from 'classnames';
import type { PropsWithChildren } from 'react';
import styles from './FormattedText.module.scss';

type FormattedTextProps = {
  className?: string;
  centered?: boolean;
  dangerous?: boolean;
  html?: string;
};

const FormattedText = ({
  centered = false,
  className,
  dangerous = false,
  html,
  ...props
}: PropsWithChildren<FormattedTextProps>) => {
  const commonProps = {
    className: cx(styles.root, centered && styles.centered, className),
    ...props,
  };

  if (dangerous && html) {
    return <InnerHtml html={html} {...commonProps} />;
  }

  return (
    <div
      {...(html && {
        dangerouslySetInnerHTML: {
          __html: html,
        },
      })}
      {...commonProps}
    />
  );
};

export { FormattedText };
