import { Button, Modal, type ButtonProps } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useModal } from 'modules/shared';
import {
  BISQUITS_EXTENDED_SETTINGS_FORM_ID,
  BisquitsExtendedSettings,
} from './BisquitsExtendedSettings';
import { useBisquits } from './hooks';

const BisquitsExtendedSettingsModal = () => {
  const { t } = useTranslation();
  const { isModalOpen, close } = useModal('bisquitsExtendedSettings');
  const { accept } = useBisquits({ onSuccess: close });

  const buttonProps: ButtonProps = {
    variant: 'black',
    size: 'm',
    onClick: () => accept(null),
  };

  return (
    <Modal
      open={isModalOpen}
      closeButtonShown={false}
      contentClassName="p-3 pb-0"
      title={t('cookieNotice.manageAction')}
      overlayClassName="bg-black/50"
      boxClassName="'h-auto m-2 h-full max-h-[95%] max-w-80 rounded-l sm:max-h-[680px] md:h-auto"
      footer={
        <div className="flex flex-col justify-end gap-2 sm:flex-row">
          <Button {...buttonProps} className="w-full sm:hidden sm:w-auto">
            {t('cookieNotice.acceptAllAction')}
          </Button>

          <Button
            type="submit"
            form={BISQUITS_EXTENDED_SETTINGS_FORM_ID}
            variant="outlined"
            size="m"
            className="w-full sm:w-auto"
          >
            {t('cookieNotice.acceptSelectionAction')}
          </Button>

          <Button {...buttonProps} className="hidden w-full sm:block sm:w-auto">
            {t('cookieNotice.acceptAllAction')}
          </Button>
        </div>
      }
    >
      <BisquitsExtendedSettings />
    </Modal>
  );
};

export { BisquitsExtendedSettingsModal };
