import { Trans, useTranslation } from 'next-i18next';
import { LinkButton, Text } from '@carvertical/ui';
import { useRouteData } from 'context/RouteDataProvider';
import { Link } from 'components/common/Link';

type BisquitActions = {
  onOpenSettings: () => void;
  acceptAll: () => void;
  acceptNecessary?: () => void;
};

type BisquitsQuickSettingsTextProps = Pick<BisquitActions, 'onOpenSettings'>;

const LINK_CLASS_NAME = 'text-s hover:no-underline';

const BisquitsQuickSettingsText = ({ onOpenSettings }: BisquitsQuickSettingsTextProps) => {
  const { t } = useTranslation();
  const { pages } = useRouteData();

  return (
    <div className="mb-4 flex w-full flex-col md:mb-0 md:max-w-[680px]">
      <h2 className="mb-1 text-m+">{t('cookieNotice.title')}</h2>

      <div className="flex flex-col gap-2">
        <Text variant="s">{t('cookieNotice.description.intro')}</Text>

        <Text variant="s">
          <Trans
            i18nKey="cookieNotice.description.configure"
            components={{
              1: <LinkButton onClick={onOpenSettings} className={LINK_CLASS_NAME} />,
            }}
          />{' '}
          {pages.cookiePolicy?.path && (
            <Trans
              i18nKey="cookieNotice.description.policy"
              components={{
                1: (
                  <LinkButton
                    as={Link}
                    href={pages.cookiePolicy.path}
                    target="_blank"
                    className={LINK_CLASS_NAME}
                  />
                ),
              }}
            />
          )}
        </Text>
      </div>
    </div>
  );
};

export { BisquitsQuickSettingsText };
export type { BisquitsQuickSettingsTextProps };
