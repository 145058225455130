import { Text } from '@carvertical/ui';
import { map } from 'lodash';
import type { Table } from 'types/content';
import styles from './PolicyTable.module.scss';

type PolicyTableProps = {
  data: Table;
};

const PolicyTable = ({ data: { items } }: PolicyTableProps) => {
  const body = items.slice(1);

  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead className={styles.head}>
          <tr>
            {map(items[0], (item, itemKey) => (
              <th className={styles.headCell} key={itemKey}>
                {item[0].text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {map(body, (row, rowKey) => (
            <tr key={rowKey}>
              {map(row, (cell, cellKey) => (
                <td className={styles.cell} key={cellKey}>
                  {map(cell, (item, itemKey) => (
                    <Text align="left" className={styles.text} key={itemKey}>
                      {item.text}
                    </Text>
                  ))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { PolicyTable };
export type { PolicyTableProps };
