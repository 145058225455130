import { Container, Grid, GridItem, Heading, LinkButton, Stack } from '@carvertical/ui';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'next-i18next';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
// eslint-disable-next-line import/no-cycle
import Page from 'components/common/Page';
import { useRouteData } from 'context/RouteDataProvider';
import { createEmailAnchor } from 'utils/text';
import { creditValidityMonths } from 'config';
import { FormattedText } from 'components/common/FormattedText';
import {
  TableOfContents,
  type TableOfContentsProps,
} from 'modules/content/components/blog/TableOfContents';
import { Link } from 'components/common/Link';
import { PolicyTable } from './PolicyTable';
import styles from './PolicyPage.module.scss';

type PolicyPageProps = {
  formatter: (content: string) => string;
  contentBeforeChildren?: React.ReactNode;
};

const SUPPORT_EMAIL_REGEX = /\[SUPPORT_EMAIL]/g;
const CREDIT_VALIDITY_MONTHS_REGEX = /\[CREDIT_VALIDITY_MONTHS]/g;
const INFO_EMAIL_REGEX = /\[INFO_EMAIL]/g;
const HEADING_REGEX = /<h2>/g;
const TOC_CONTENT_START_REGEX = /<h2 (id="(.+?)")>/;
const TOC_CONTENT_END_REGEX = /<\/h2>/;
const TOC_REGEX = /<h2 (id="(.+?)")>(.+?)<\/h2>/g;
const TOC_ID_START_REGEX = /<h2 id="/g;
const TOC_ID_END_REGEX = /">(.+?)<\/h2>/g;

const getTocId = (header: string) =>
  header.replace(TOC_ID_START_REGEX, '').replace(TOC_ID_END_REGEX, '');

const getTocContent = (header: string) =>
  header.replace(TOC_CONTENT_START_REGEX, '').replace(TOC_CONTENT_END_REGEX, '');

const PolicyPage = ({ contentBeforeChildren, formatter }: PolicyPageProps) => {
  const { t } = useTranslation();
  const { market, page } = useRouteData();

  if (!page) {
    return null;
  }

  const renderTable = () => (
    <>{page.body?.length ? <PolicyTable data={page.body[0]} key={page.body[0].id} /> : null}</>
  );

  const formatHtml = () => {
    const content = page.content.html
      .replace(SUPPORT_EMAIL_REGEX, createEmailAnchor(market.emails.support))
      .replace(CREDIT_VALIDITY_MONTHS_REGEX, creditValidityMonths.toString())
      .replace(INFO_EMAIL_REGEX, createEmailAnchor(market.emails.info))
      .replaceAll(HEADING_REGEX, (_, id) => `<h2 id="heading-${id}">`)
      .replace('--PolicyTable--', ReactDOMServer.renderToString(renderTable()));

    return formatter ? formatter(content) : content;
  };

  const html = formatHtml();
  const headers = html.match(TOC_REGEX);

  const tocLinks: TableOfContentsProps['links'] | undefined = headers?.map((header) => ({
    id: getTocId(header),
    children: getTocContent(header),
  }));

  return (
    <Page title={page.title} breadcrumbs={false}>
      <div className={styles.backButtonWrapper}>
        <Container>
          <LinkButton as={Link} variant="blue" leadingIcon="arrow-left" href="/policies">
            {t('policies.title')}
          </LinkButton>
        </Container>
      </div>

      <section className={styles.wrapper}>
        <Container>
          <Grid
            as="article"
            rowGap={{ mobileUp: 5 }}
            columnGap={{ mobileUp: 0, tabletLandscapeUp: 4 }}
          >
            {!!tocLinks?.length && (
              <GridItem span={{ mobileUp: 12, tabletLandscapeUp: 3 }}>
                <TableOfContents links={tocLinks} />
              </GridItem>
            )}

            <GridItem
              className={styles.contentWrapper}
              span={{ mobileUp: 12, tabletLandscapeUp: 6 }}
              start={{ mobileUp: 1, tabletLandscapeUp: 4 }}
            >
              <Heading as="h1" variant="l">
                {page.title}
              </Heading>

              {contentBeforeChildren && <Stack gap={0}>{contentBeforeChildren}</Stack>}

              <FormattedText className={styles.content} html={html} />
            </GridItem>
          </Grid>
        </Container>
      </section>
    </Page>
  );
};

export { PolicyPage };
export type { PolicyPageProps };
