import PropTypes from 'prop-types';
import cx from 'classnames';
import { SkeletonWrapper } from '@carvertical/ui';
import styles from './Table.module.scss';

/**
 * @deprecated Use Table from `@carvertical/ui` instead, when ready
 */
const Table = ({ headers, data, className, headClassName, cellClassName, loading, ...props }) => (
  <table className={cx(styles.root, className)} {...props}>
    <thead id="head" className={cx(styles.head, headClassName)}>
      <tr>
        {headers.map(({ className, text, id }) => (
          <th className={cx(styles.headCell, cellClassName, className)} key={id} tabIndex="0">
            <SkeletonWrapper loading={loading} radius="s">
              {text}
            </SkeletonWrapper>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map(({ className, ...entry }) => (
        <tr key={entry.id}>
          {Object.values(headers).map(({ id }) => (
            <td key={id} className={cx(styles.cell, cellClassName, className)}>
              <SkeletonWrapper loading={loading} radius="l">
                {entry[id] ?? null}
              </SkeletonWrapper>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  ).isRequired,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headClassName: PropTypes.string,
};

Table.defaultProps = {
  className: undefined,
  cellClassName: undefined,
  headClassName: undefined,
};

export default Table;
