import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'next-i18next';
import { Button } from '@carvertical/ui';
import Table from 'components/common/ui/Table';
import { useRouteData } from 'context/RouteDataProvider';
import { useModal } from 'modules/shared';
import type { Cookie } from '../types';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469 https://carvertical.atlassian.net/browse/LAUNCH-2469
// eslint-disable-next-line import/no-cycle
import { PolicyPage } from './PolicyPage';
import styles from './CookiePolicyPage.module.scss';

type CookiePolicyPageRouteData = {
  fetchedData: Cookie[];
};

const COOKIE_TABLE_REGEX = /&lt;CookieAuditTable category=&quot;(.+?)&quot; \/&gt;/g;
const COOKIE_TABLE_START_REGEX = /&lt;CookieAuditTable category=&quot;/g;
const COOKIE_TABLE_END_REGEX = /&quot; \/&gt;/g;
const INFINITY = '∞';

const matchCookieTableCategory = (category: string) =>
  `&lt;CookieAuditTable category=&quot;${category}&quot; /&gt;`;

const getDuration = (duration: string) => Number(duration) || INFINITY;
const getCookieTableCategories = (html: string) => {
  const categories = [...(html.match(COOKIE_TABLE_REGEX) ?? [])];
  return (
    categories?.map((category) =>
      category.replace(COOKIE_TABLE_START_REGEX, '').replace(COOKIE_TABLE_END_REGEX, ''),
    ) ?? []
  );
};

const CookiePolicyPage = () => {
  const { t } = useTranslation();
  const { open: openBisquitsExtendedSettingsModal } = useModal('bisquitsExtendedSettings');
  const routeData = useRouteData<CookiePolicyPageRouteData>();

  const formatHtml = (html: string) => {
    let finalHtml = html;
    const categories = getCookieTableCategories(finalHtml);

    categories.forEach((category) => {
      const cookies = routeData.fetchedData.filter(({ Category }) => Category === category);

      finalHtml = finalHtml.replace(
        matchCookieTableCategory(category),
        ReactDOMServer.renderToString(
          // @ts-expect-error TS(2739)
          <Table
            className={styles.table}
            cellClassName={styles.cell}
            headers={[
              { id: 'identifier', text: t('general.idLabel') },
              { id: 'duration', text: t('general.daysDuration') },
              {
                id: 'description',
                text: t('general.descriptionLabel'),
              },
            ]}
            data={cookies.map(({ ExpireDays, Name, PurposeDescription }) => ({
              id: Name,
              duration: getDuration(ExpireDays),
              identifier: Name,
              description: PurposeDescription,
            }))}
          />,
        ),
      );
    });

    return finalHtml;
  };

  return (
    <PolicyPage
      formatter={formatHtml}
      contentBeforeChildren={
        <Button onClick={openBisquitsExtendedSettingsModal} className={styles.manageCookiesButton}>
          {t('cookieNotice.manageAction')}
        </Button>
      }
    />
  );
};

export { CookiePolicyPage };
