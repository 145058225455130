import { BisquitsQuickSettingsModal } from './BisquitsQuickSettingsModal';
import { BisquitsExtendedSettingsModal } from './BisquitsExtendedSettingsModal';

const BisquitsModal = () => (
  <>
    <BisquitsQuickSettingsModal />

    <BisquitsExtendedSettingsModal />
  </>
);

export { BisquitsModal };
