import type { CookieCategory } from './types';

const POLICY_UPDATE_DATE = new Date('2024-02-06');

const COOKIE_CATEGORIES: CookieCategory[] = [
  {
    id: 'mandatoryCookies',
    required: true,
    disabled: true,
    defaultValue: true,
    cookiebotCategory: 1,
  },
  {
    id: 'statsCookies',
    defaultValue: false,
    cookiebotCategory: 3,
  },
  {
    id: 'marketingCookies',
    defaultValue: false,
    cookiebotCategory: [2, 4],
  },
];

export { POLICY_UPDATE_DATE, COOKIE_CATEGORIES };
