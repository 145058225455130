import { Fragment } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { LinkButton, Text, ToggleSwitch } from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouteData } from 'context/RouteDataProvider';
import { Link } from 'components/common/Link';
import { useModal } from 'modules/shared';
import { bisquitsSettingsSchema } from '../../schemas';
import type { SavedCookieCategories } from '../../types';
import { COOKIE_CATEGORIES } from '../../constants';
import { useBisquits } from './hooks';

const BISQUITS_EXTENDED_SETTINGS_FORM_ID = 'bisquitsExtendedSettingsForm';

const BisquitsExtendedSettings = () => {
  const { t } = useTranslation();
  const { pages } = useRouteData();
  const { close } = useModal('bisquitsExtendedSettings');
  const { accept, defaultSelectedCookies } = useBisquits({ onSuccess: close });

  const form = useForm<SavedCookieCategories>({
    defaultValues: defaultSelectedCookies,
    resolver: zodResolver(bisquitsSettingsSchema),
  });
  const { handleSubmit, control } = form;

  return (
    <div>
      <h2 className="mb-1 text-m+">{t('cookieNotice.cookieSettingsLabel')}</h2>

      <Text variant="s" className="mb-4">
        {t('cookieNotice.description.intro')}{' '}
        <Trans
          i18nKey="cookieNotice.description.policy"
          components={{
            1: <LinkButton as={Link} href={pages.cookiePolicy.path} target="_blank" />,
          }}
        />
      </Text>

      <FormProvider {...form}>
        <form
          id={BISQUITS_EXTENDED_SETTINGS_FORM_ID}
          onSubmit={handleSubmit((values) => accept(values))}
          className="pb-3"
        >
          {COOKIE_CATEGORIES.map(({ disabled = false, id, required = false }, index) => {
            const dividerShown = index < COOKIE_CATEGORIES.length - 1;

            return (
              <Fragment key={id}>
                <div className="flex justify-between gap-1.5">
                  <div className="flex flex-col gap-1.5">
                    <h3 className="text-s+">{t(`cookieNotice.${id}.label`)}</h3>

                    <Text variant="s">{t(`cookieNotice.${id}.description`)}</Text>
                  </div>

                  <div>
                    <Controller
                      name={id}
                      control={control}
                      render={({ field: { name, onChange, value } }) => (
                        <ToggleSwitch
                          size="l"
                          label={t(`cookieNotice.${id}.label`)}
                          id={name}
                          labelHidden
                          disabled={disabled}
                          checked={value}
                          required={required}
                          onChange={(event) => onChange(event.target.checked)}
                        />
                      )}
                    />
                  </div>
                </div>

                {dividerShown && <div className="my-2 h-px bg-grey-200" />}
              </Fragment>
            );
          })}
        </form>
      </FormProvider>
    </div>
  );
};

export { BisquitsExtendedSettings, BISQUITS_EXTENDED_SETTINGS_FORM_ID };
