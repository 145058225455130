import * as cookieConsent from 'services/cookieConsent';
import { COOKIE_CATEGORIES } from '../../../constants';
import { type SavedCookieCategories, useBisquitsActions } from './useBisquitsStore';

type UseBisquitsProps = {
  onSuccess?: () => void;
};

const useBisquits = ({ onSuccess }: UseBisquitsProps) => {
  const { saveCookies, removeCookies, pushToDataLayer } = useBisquitsActions();

  const selectedCookies = cookieConsent.get();
  const defaultValues = Object.fromEntries(
    COOKIE_CATEGORIES.map(({ id, defaultValue }) => [id, defaultValue]),
  );
  const defaultSelectedCookies = selectedCookies || defaultValues;
  const allCookieCategories = getCookieCategories('all');
  const necessaryCookieCategories = getCookieCategories('necessary');

  const accept = (cookieCategories: SavedCookieCategories | null) => {
    saveCookies(cookieCategories || allCookieCategories);
    onSuccess?.();
  };

  const acceptAll = () => {
    saveCookies(allCookieCategories);
    onSuccess?.();
  };

  const acceptNecessary = () => {
    saveCookies(necessaryCookieCategories);
    onSuccess?.();
  };

  return {
    saveCookies,
    removeCookies,
    pushToDataLayer,
    allCookieCategories,
    accept,
    acceptAll,
    acceptNecessary,
    defaultSelectedCookies,
  };
};

function getCookieCategories(type: 'all' | 'necessary'): SavedCookieCategories {
  return COOKIE_CATEGORIES.reduce(
    (values, category) => ({
      ...values,
      [category.id]: type === 'all' ? true : !!category.required,
    }),
    {
      mandatoryCookies: false,
      statsCookies: false,
      marketingCookies: false,
    },
  );
}

export { useBisquits };
export type { UseBisquitsProps };
