import { Container, Grid, GridItem, Heading, Stack } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import type { ParseKeys } from 'i18next';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
// eslint-disable-next-line import/no-cycle
import Page from 'components/common/Page';
import { useRouteData } from 'context/RouteDataProvider';
import PoliciesIllustration from '../assets/mascot-policies.svg';
import { PolicyCard } from './PolicyCard';
import styles from './PoliciesPage.module.scss';

type Policy = {
  descriptionId: ParseKeys;
  pageId: string;
};

const POLICIES: Policy[] = [
  {
    descriptionId: 'policies.privacyDescription',
    pageId: 'privacy',
  },
  {
    descriptionId: 'policies.vehicleDataDescription',
    pageId: 'vehicle-data-privacy-notice',
  },
  {
    descriptionId: 'policies.cookieDescription',
    pageId: 'cookiePolicy',
  },
];

const PoliciesPage = () => {
  const { t } = useTranslation();
  const { pages } = useRouteData();

  return (
    <Page title={t('policies.title')} breadcrumbs={false}>
      <section className={styles.wrapper}>
        <Container>
          <Stack gap={{ mobileUp: 4, tabletLandscapeUp: 5 }}>
            <Heading as="h1" variant="l">
              {t('policies.title')}
            </Heading>

            <Grid
              columnGap={{ mobileUp: 0, tabletLandscapeUp: 4 }}
              rowGap={{ mobileUp: 2, tabletLandscapeUp: 4 }}
            >
              {POLICIES.map((policy, index) => {
                const { path, title } = pages[policy.pageId];

                return (
                  <GridItem
                    className={styles.policy}
                    style={{ '--order': index === 0 ? -1 : 0 }}
                    span={{ mobileUp: 12, tabletLandscapeUp: 6 }}
                    key={pages[policy.pageId].id}
                  >
                    <PolicyCard title={title} description={t(policy.descriptionId)} href={path} />
                  </GridItem>
                );
              })}

              <GridItem
                className={styles.illustrationWrapper}
                span={{ mobileUp: 12, tabletLandscapeUp: 6 }}
              >
                <PoliciesIllustration className={styles.illustration} />
              </GridItem>
            </Grid>
          </Stack>
        </Container>
      </section>
    </Page>
  );
};

export { PoliciesPage };
